import React, { useMemo } from 'react';
import CcBackup, {
    CcBackupMethod,
    CcBackupStatus,
} from '../../services/models/CcBackup';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../common/Navigation/ActionsMenu';
import BackupLogsActionButton from './ActionButton/BackupLogsActionButton';
import BackupSetDetailsButton from './ActionButton/BackupSetDetailsButton';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import BackupCloudUploadModal from './BackupCloudUploadModal';
import BackupCloudDownloadModal from './BackupCloudDownloadModal';
import CcCluster, { CcClusterType } from '../../services/models/CcCluster';
import JobActionButton from '../Jobs/JobActionButton';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import { ActionMenuItem } from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';
import BackupClusterCreateActionButton from './ActionButton/BackupClusterCreateActionButton';
import BackupDeleteActionButton from './ActionButton/BackupDeleteActionButton';
import BackupRestoreActionButton from './ActionButton/BackupRestoreActionButton';
import { groupSimilarMenuItemsInSubmenu } from '../../common/Navigation/actionMenuHelpers';
import {
    DeleteOutlined,
    DownloadOutlined,
    FileTextOutlined,
    PlusCircleOutlined,
    ProfileOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import { CloudIcon, ReloadIcon } from '../../common/icons/icons';

export enum BackupAction {
    RESTORE = 'RESTORE',
    DELETE = 'DELETE',
    GET_LOGS = 'GET_LOGS',
    VIEW_SET = 'VIEW_SET',
    UPLOAD = 'UPLOAD',
    DOWNLOAD = 'DOWNLOAD',
    VIEW_STORAGES = 'VIEW_STORAGES',
    CREATE_CLUSTER = 'CREATE_CLUSTER',
}

export function getBackupActionFormatText(action?: string) {
    switch (action) {
        case BackupAction.RESTORE:
            return 'Restore';
        case BackupAction.GET_LOGS:
            return 'Logs';
        case BackupAction.DELETE:
            return 'Delete';
        case BackupAction.VIEW_SET:
            return 'View set';
        case BackupAction.UPLOAD:
            return 'Upload';
        case BackupAction.DOWNLOAD:
            return 'Download';
        case BackupAction.VIEW_STORAGES:
            return 'View storage locations';
        default:
            return action;
    }
}

export type BackupActionsMenuProps = ActionsMenuProps & {
    backup: CcBackup;
    cluster: CcCluster;
    onActionPerformed?: (action: BackupAction) => void;
};
export default function BackupActionsMenu({
    backup,
    cluster,
    onActionPerformed,
    ...rest
}: BackupActionsMenuProps) {
    const handleActionSuccess = (action: BackupAction) => {
        onActionPerformed?.(action);
    };

    const items = useMemo(() => {
        const restoreBackupAction =
            backup.getStatus() === CcBackupStatus.STATUS_COMPLETED
                ? {
                      key: BackupAction.RESTORE,
                      icon: <ReloadIcon />,
                      label: (
                          <BackupRestoreActionButton
                              cluster={cluster}
                              backup={backup}
                              onSuccess={() => {
                                  handleActionSuccess(BackupAction.RESTORE);
                              }}
                          />
                      ),
                  }
                : null;

        const viewSetAction =
            backup.getChildren() && backup.getChildren() > 0
                ? {
                      key: BackupAction.VIEW_SET,
                      icon: <ProfileOutlined />,
                      label: (
                          <BackupSetDetailsButton backup={backup}>
                              {getBackupActionFormatText(BackupAction.VIEW_SET)}
                          </BackupSetDetailsButton>
                      ),
                  }
                : null;

        const cloudUploadAction =
            cluster &&
            !cluster.isType(CcClusterType.TYPE_ELASTIC) &&
            !cluster.isType(CcClusterType.TYPE_REDIS_SHARDED) &&
            backup.isLocal() &&
            !backup.isMethod(CcBackupMethod.MONGODB_PERCONA) &&
            !backup.isMethodPgBackrest() &&
            !cluster.isType(CcClusterType.TYPE_MYSQL_CLUSTER)
                ? {
                      // NDB
                      key: BackupAction.UPLOAD,
                      icon: <UploadOutlined />,
                      label: (
                          <ButtonWithForm
                              button={
                                  <JobActionButton
                                      command={
                                          CmonJobInstanceCommand.UPLOAD_BACKUP_DATA_TO_CLOUD_STORAGE
                                      }
                                      clusterId={cluster?.clusterId}
                                      extraKey={[backup.getId()]}
                                  >
                                      {getBackupActionFormatText(
                                          BackupAction.UPLOAD
                                      )}
                                  </JobActionButton>
                              }
                              form={
                                  <BackupCloudUploadModal
                                      backup={backup}
                                      onSuccess={() =>
                                          handleActionSuccess(
                                              BackupAction.UPLOAD
                                          )
                                      }
                                  />
                              }
                          />
                      ),
                  }
                : null;

        const cloudDownloadAction =
            cluster &&
            !cluster.isType(CcClusterType.TYPE_ELASTIC) &&
            backup.isInTheCloud()
                ? {
                      key: BackupAction.DOWNLOAD,
                      icon: <DownloadOutlined />,
                      label: (
                          <ButtonWithForm
                              button={
                                  <JobActionButton
                                      command={
                                          CmonJobInstanceCommand.DOWNLOAD_BACKUP_DATA_FROM_CLOUD_STORAGE
                                      }
                                      clusterId={cluster.clusterId}
                                      extraKey={[backup.getId()]}
                                  >
                                      {getBackupActionFormatText(
                                          BackupAction.DOWNLOAD
                                      )}
                                  </JobActionButton>
                              }
                              form={
                                  <BackupCloudDownloadModal
                                      backup={backup}
                                      cluster={cluster}
                                      onSuccess={() =>
                                          handleActionSuccess(
                                              BackupAction.DOWNLOAD
                                          )
                                      }
                                  />
                              }
                          />
                      ),
                  }
                : null;

        const createCluster =
            cluster.clusterType === CcClusterType.TYPE_GALERA &&
            backup.getStatus() === CcBackupStatus.STATUS_COMPLETED
                ? {
                      key: BackupAction.CREATE_CLUSTER,
                      icon: <PlusCircleOutlined />,
                      label: (
                          <BackupClusterCreateActionButton
                              cluster={cluster}
                              backup={backup}
                          />
                      ),
                  }
                : null;

        const viewLogs = {
            key: BackupAction.GET_LOGS,
            icon: <FileTextOutlined />,
            label: (
                <BackupLogsActionButton
                    backup={backup}
                    onSuccess={() => handleActionSuccess(BackupAction.GET_LOGS)}
                >
                    {getBackupActionFormatText(BackupAction.GET_LOGS)}
                </BackupLogsActionButton>
            ),
        };

        const deleteAction = {
            key: BackupAction.DELETE,
            icon: <DeleteOutlined />,
            critical: true,
            label: (
                <BackupDeleteActionButton
                    cluster={cluster}
                    backup={backup}
                    onSuccess={() => {
                        handleActionSuccess(BackupAction.DELETE);
                    }}
                />
            ),
        };

        if (backup.isMethod(CcBackupMethod.PROXYSQL)) {
            return [viewLogs, deleteAction];
        }

        const cloudGroup = [cloudUploadAction, cloudDownloadAction].filter(
            Boolean
        );

        const cloud = groupSimilarMenuItemsInSubmenu(
            {
                key: 'backup-cloud',
                label: 'Cloud options',
                icon: <CloudIcon />,
            },
            cloudGroup
        );

        return [
            restoreBackupAction,
            viewSetAction,
            createCluster,
            cloud,
            viewLogs,
            deleteAction,
        ].filter((item) => !!item);
    }, [cluster, backup]);

    return <ActionsMenu items={items as ActionMenuItem[]} {...rest} />;
}
