import React from 'react';
import CcCluster, {
    CcClusterType,
} from '../../../../../services/models/CcCluster';
import useForm from 'antd/lib/form/hooks/useForm';
import { AddNodeWizardAction } from '../AddNodeWizard';
import ReplicationFormWizard, {
    getImportReplicationJobDataDTO,
    getNewReplicationJobDataDTO,
} from './ReplicationFormWizard';
import { CmonJobInstanceCommand } from '../../../../../services/cmon/models/CmonJobInstance';
import { AddNodeFormWizardFields } from '../AddNodeFormWizard';
import AddNodeModal from '../AddNodeModal';
import {CcNodeRole} from "../../../../../services/models/CcNode";

export default AddNodeReplicationModal;

export type AddNodeReplicationModalProps = {
    cluster: CcCluster;
    onCancel?: () => void;
    onSuccess?: () => void;
    onCommandChange?: (command: CmonJobInstanceCommand) => void;
    type?: CcNodeRole.PRIMARY | CcNodeRole.REPLICA;
};

function AddNodeReplicationModal({
    cluster,
    onCancel,
    onSuccess,
    onCommandChange,
    type = CcNodeRole.REPLICA,
}: AddNodeReplicationModalProps) {
    const [form] = useForm<any>();

    const handleSubmit = (
        fields: AddNodeFormWizardFields,
        action: AddNodeWizardAction
    ) => {
        return action === AddNodeWizardAction.NEW
            ? getNewReplicationJobDataDTO(fields, cluster)
            : getImportReplicationJobDataDTO(fields, cluster);
    };
    const addReplicaCommand = cluster.isType(CcClusterType.TYPE_REDIS_SHARDED)
        ? CmonJobInstanceCommand.ADDNODE
        : CmonJobInstanceCommand.ADD_REPLICATION_SLAVE;

    const commandNew =
        type === 'replica'
            ? addReplicaCommand
            : CmonJobInstanceCommand.ADDNODE;

    return (
        <AddNodeModal
            cluster={cluster}
            form={form}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            onSuccess={onSuccess}
            onCommandChange={onCommandChange}
            titleNew={`Create a ${type} node`}
            titleImport={`Import a ${type} node`}
            commandNew={commandNew}
            commandImport={CmonJobInstanceCommand.REGISTERNODE}
            formCreateWizard={
                <ReplicationFormWizard
                    action={AddNodeWizardAction.NEW}
                    form={form}
                    cluster={cluster}
                    type={type}
                />
            }
            formImportWizard={
                <ReplicationFormWizard
                    action={AddNodeWizardAction.IMPORT}
                    form={form}
                    cluster={cluster}
                />
            }
        />
    );
}
