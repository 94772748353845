import {
    CcBackupMethod,
    CcBackupMySQLDumpType,
    isBackupMethodMariaBackup,
    isBackupMethodXtraBackup,
} from '../../../services/models/CcBackup';
import { CcClusterType } from '../../../services/models/CcCluster';
import {
    getBackupMethodsByClusterType,
    getRedisBackupMethod,
} from '../BackupMethodSelect';
import { MULTI_SELECT_ALL_OPTION_VALUE } from '../../../common/DataEntry/MultiSelect';
import {
    BackupFormFieldsConfigInterface,
    BackupFormFieldsType,
} from './BackupFormConfigurator';

export default class BackupAvailableFieldsConfig implements BackupFormFieldsConfigInterface {
    static installBackupTool(fields: BackupFormFieldsType) {
        return [
            CcBackupMethod.MONGODB_PERCONA,
            CcBackupMethod.PGBACKREST_INC,
            CcBackupMethod.PGBACKREST_FULL,
            CcBackupMethod.PGBACKREST_DIFF,
        ].includes(fields.method as CcBackupMethod);
    }

    static dumpType(fields: BackupFormFieldsType) {
        return fields.method === CcBackupMethod.MYSQLDUMP;
    }

    static compression(fields: BackupFormFieldsType) {
        if (
            fields.clusterType &&
            [CcClusterType.TYPE_REDIS_SHARDED].includes(fields.clusterType)
        ) {
            return false;
        }
        return true;
    }

    static compressionLevel(fields: BackupFormFieldsType) {
        if (
            fields.clusterType &&
            [CcClusterType.TYPE_REDIS_SHARDED].includes(fields.clusterType)
        ) {
            return false;
        }
        return fields.compression || false;
    }

    static cloudUpload(fields: BackupFormFieldsType) {
        return ![
            CcBackupMethod.MONGODB_PERCONA,
            CcBackupMethod.PGBACKREST_INC,
            CcBackupMethod.PGBACKREST_FULL,
            CcBackupMethod.PGBACKREST_DIFF,
            ...getBackupMethodsByClusterType(CcClusterType.TYPE_MYSQL_CLUSTER),
            ...getBackupMethodsByClusterType(CcClusterType.TYPE_ELASTIC),
            ...(fields.clusterType === CcClusterType.TYPE_REDIS_SHARDED
                ? [getRedisBackupMethod()]
                : []),
        ].includes(fields.method as CcBackupMethod);
    }

    static host(fields: BackupFormFieldsType) {
        return (
            fields.clusterType !== CcClusterType.TYPE_REDIS_SHARDED &&
            ![
                CcBackupMethod.PGBACKREST_FULL,
                CcBackupMethod.PGBACKREST_DIFF,
                CcBackupMethod.PGBACKREST_INC,
                CcBackupMethod.MYSQL_BINLOG,
                ...getBackupMethodsByClusterType(CcClusterType.TYPE_MONGODB),
                ...getBackupMethodsByClusterType(CcClusterType.TYPE_ELASTIC),
                ...getBackupMethodsByClusterType(
                    CcClusterType.TYPE_MSSQL_AO_ASYNC
                ),
                ...getBackupMethodsByClusterType(
                    CcClusterType.TYPE_MSSQL_SINGLE
                ),
            ].includes(fields.method as CcBackupMethod)
        );
    }

    static enableRetention(fields: BackupFormFieldsType) {
        return ![
            CcBackupMethod.PGBACKREST_FULL,
            CcBackupMethod.PGBACKREST_DIFF,
            CcBackupMethod.PGBACKREST_INC,
        ].includes(fields.method as CcBackupMethod);
    }

    static splitDumpFiles(fields: BackupFormFieldsType) {
        if (
            fields.method === CcBackupMethod.MYSQLDUMP &&
            fields.dumpType === CcBackupMySQLDumpType.MYSQL_DB_ONLY
        ) {
            return false;
        }
        return fields.method === CcBackupMethod.MYSQLDUMP;
    }

    static enablePartial(fields: BackupFormFieldsType) {
        if (
            fields.method === CcBackupMethod.MYSQLDUMP &&
            fields.dumpType === CcBackupMySQLDumpType.MYSQL_DB_ONLY
        ) {
            return false;
        }
        return [
            CcBackupMethod.MYSQLDUMP,
            CcBackupMethod.XTRABACKUP_FULL,
            CcBackupMethod.MARIABACKUP_FULL,
            CcBackupMethod.PGDUMP,
        ].includes(fields.method as CcBackupMethod);
    }

    static verifyBackup(fields: BackupFormFieldsType) {
        if (!fields.isSchedule) {
            return false;
        }
        if (
            fields.clusterType &&
            [CcClusterType.TYPE_REDIS_SHARDED].includes(fields.clusterType)
        ) {
            return false;
        }
        return ![
            ...getBackupMethodsByClusterType(CcClusterType.TYPE_ELASTIC),
            ...getBackupMethodsByClusterType(CcClusterType.TYPE_MONGODB),
        ].includes(fields.method as CcBackupMethod);
    }

    static failoverBackup(fields: BackupFormFieldsType) {
        return !!fields.isSchedule;
    }

    static includeTables(fields: BackupFormFieldsType) {
        return (
            fields.enablePartial === true &&
            (fields.partialDatabases?.length || 0) === 1 &&
            fields.partialDatabases?.find(
                (db) => db === MULTI_SELECT_ALL_OPTION_VALUE
            ) === undefined
        );
    }

    static excludeTables(fields: BackupFormFieldsType) {
        return (
            fields.enablePartial === true &&
            (fields.partialDatabases?.length || 0) === 1 &&
            fields.partialDatabases?.find(
                (db) => db === MULTI_SELECT_ALL_OPTION_VALUE
            ) === undefined
        );
    }

    static useQPress(fields: BackupFormFieldsType) {
        return (
            (fields.compression &&
                fields.method !== CcBackupMethod.MYSQLDUMP) ||
            false
        );
    }

    static extendedInsert(fields: BackupFormFieldsType) {
        return fields.method === CcBackupMethod.MYSQLDUMP;
    }

    static wsrepDeSync(fields: BackupFormFieldsType) {
        return (
            fields.clusterType === CcClusterType.TYPE_GALERA &&
            (isBackupMethodMariaBackup(fields.method as CcBackupMethod) ||
                isBackupMethodXtraBackup(fields.method as CcBackupMethod))
        );
    }

    static xtrabackupBackupLocks(fields: BackupFormFieldsType) {
        return fields.method !== CcBackupMethod.MYSQLDUMP;
    }

    static xtrabackupLockDdlPerTable(fields: BackupFormFieldsType) {
        return fields.method !== CcBackupMethod.MYSQLDUMP;
    }

    static xtrabackupParallellism(fields: BackupFormFieldsType) {
        return fields.method !== CcBackupMethod.MYSQLDUMP;
    }

    static throttleRateNet(fields: BackupFormFieldsType) {
        return fields.method !== CcBackupMethod.MYSQLDUMP;
    }

    static usePIGZ(fields: BackupFormFieldsType) {
        return fields.method !== CcBackupMethod.MYSQLDUMP;
    }

    static encryptBackup(fields: BackupFormFieldsType) {
        if (
            fields.clusterType &&
            [CcClusterType.TYPE_REDIS_SHARDED].includes(fields.clusterType)
        ) {
            return false;
        }
        return fields.method !== CcBackupMethod.MONGODB_PERCONA;
    }

    static scheduleName(fields: BackupFormFieldsType) {
        return !!fields.isSchedule;
    }

    static cloudEnableRetention(fields: BackupFormFieldsType) {
        return fields.method !== CcBackupMethod.MYSQL_BINLOG;
    }
}
