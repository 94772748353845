import CmonHost, { CmonHostProps } from '../cmon/models/CmonHost';
import CcNodeReplicationSlave, {
    CcNodeReplicationSlaveProps,
} from './CcNodeReplicationSlave';
import { CcClusterVendor } from './CcCluster';
import CcHostDistribution from './CcHostDistribution';
import CcJob from './CcJob';

export enum CcNodeStatus {
    CmonHostUnknown = 'CmonHostUnknown',
    CmonHostOnline = 'CmonHostOnline',
    CmonHostOffLine = 'CmonHostOffLine',
    CmonHostFailed = 'CmonHostFailed',
    CmonHostRecovery = 'CmonHostRecovery',
    CmonHostShutDown = 'CmonHostShutDown',
}

export enum CcNodeBase {
    BASE_POSTGRESQL = 'postgresql',
    BASE_UNKNOWN = 'unknown',
}

export enum CcNodeStatusGroup {
    info = -1,
    success = 0,
    warning = 1,
    error = 2,
}

export enum CcNodeType {
    CONTROLLER = 'controller',
    MYSQL = 'mysql',
    GALERA = 'galera',
    POSTGRESQL = 'postgres',
    PGBOUNCER = 'pgbouncer',
    GROUP_REPLICATION = 'grouprepl',
    MONGO = 'mongo',
    HAPROXY = 'haproxy',
    KEEPALIVED = 'keepalived',
    PROXYSQL = 'proxysql',
    NDB = 'NDBD',
    NDB_MGMD = 'NDB_MGMD',
    CMON_AGENT = 'cmonagent',
    GARBD = 'garbd',
    MEMCACHED = 'memcached',
    MAXSCALE = 'maxscale',
    PROMETHEUS = 'prometheus',
    PBM_AGENT = 'pbmagent',
    REDIS = 'redis',
    REDIS_SENTINEL = 'sentinel',
    REDIS_SHARDED = 'redis_sharded',
    MSSQL = 'mssql',
    PGBACKREST = 'pgbackrest',
    ELASTIC = 'elastic',

    // @todo: frontend added type, remove comment if backend will return proper type
    TIMESCALEDB = 'timescaledb',
}

export enum CcNodeRole {
    CONTROLLER = 'controller',
    MONGO_DATA_NODE = 'shardsvr',
    MONGO_CONFIG_SERVER = 'configsvr',
    MONGO_MONGOS = 'mongos',
    MONGO_ARBITER = 'arbiter',
    MASTER = 'master',
    PRIMARY = 'primary',
    SLAVE = 'slave',
    SECONDARY = 'secondary',

    REPLICA = 'replica',
    MULTI = 'multi',
    BVS = 'bvs', // mysql role: backup verification server
    NONE = 'none',
    BACKUP_REPO = 'backuprepo',
    RESOLVING = 'resolving',

    KEEPALIVED_PRIMARY = 'master',
    KEEPALIVED_BACKUP = 'backup',

    // @todo: frontend added role, remove comment when backend supports 'intermediate' role
    INTERMEDIATE = 'intermediate',
    FAILED_PRIMARY = 'failed primary',
}

export interface CcNodeProps extends CmonHostProps {
    hoststatus?: CcNodeStatus;
    vendor?: CcClusterVendor;
    deployment_job_id?: number;
}

export default class CcNode extends CmonHost {
    public readonly deploymentJobId?: number;
    public readonly nodetype?: CcNodeType;
    public readonly hoststatus?: CcNodeStatus;
    public readonly port?: number;
    public readonly vendor?: CcClusterVendor;

    // override
    public readonly replicationSlave?: CcNodeReplicationSlave | any;

    public readonly distribution?: CcHostDistribution;

    constructor(props: CcNodeProps) {
        super(props);
        this.nodetype = props.nodetype as CcNodeType;
        this.hoststatus = props.hoststatus as CcNodeStatus;
        if (!this.hoststatus) {
            this.hoststatus = CcNodeStatus.CmonHostUnknown;
        }
        this.replicationSlave =
            props.replication_slave &&
            new CcNodeReplicationSlave(
                props.replication_slave as CcNodeReplicationSlaveProps
            );
        this.port = props.port ? parseInt(props.port as any, 10) : undefined;
        this.vendor = props.vendor;
        this.role = (
            (props.role as CcNodeRole) || CcNodeRole.NONE
        ).toLowerCase();
        this.deploymentJobId = props.deployment_job_id;
        this.distribution = new CcHostDistribution(props.distribution || {});
    }

    public getKey(withClusterId?: boolean): string {
        return `${this.getAddress()}${
            (withClusterId && `-${this.clusterid}`) || ''
        }`;
    }

    public getClusterKey(): string {
        return `${this.clusterid}`;
    }

    public getName() {
        return this.getAddress();
    }

    public getType(): CcNodeType {
        return this.nodetype as CcNodeType;
    }

    public getHostWithPort() {
        return `${this.hostname}:${this.port}`;
    }
    public getAddress() {
        return createNodeAddress(this.nodetype, this.hostname, this.port);
    }

    /**
     * Node with same base are expecting to be represented similarly in UI
     * e.g POSTGRESQL and TIMESCALEDB
     */
    public getBase(): CcNodeBase {
        return getBase(this.nodetype);
    }

    public setRole(role: CcNodeRole) {
        this.role = role;
    }

    public getRole() {
        return this.role;
    }

    public isType(type: CcNodeType | CcNodeType[]) {
        if (Array.isArray(type)) {
            return this.nodetype && type.includes(this.nodetype);
        }
        return this.nodetype === type;
    }
    public isRole(role: CcNodeRole) {
        return this.role === role;
    }

    public isMaintenanceModeEnabled() {
        return !!this.maintenanceModeActive;
    }

    public isDatabaseNode(excludeNodeRoles?: CcNodeRole[]) {
        const excludeRoles = excludeNodeRoles || [CcNodeRole.BVS];
        return (
            getDatabaseNodeTypes().includes(this.nodetype as CcNodeType) &&
            !excludeRoles.includes(this.role as CcNodeRole)
        );
    }

    public isBase(base: CcNodeBase): boolean {
        return this.getBase() === base;
    }

    public isLoadBalancer() {
        return isNodeLoadBalancer(this);
    }

    public isPerformanceHaNode() {
        return getPerformanceHaNodeTypes().includes(
            this.nodetype as CcNodeType
        );
    }

    public isPrimary() {
        return (
            this.isRole(CcNodeRole.MASTER) || this.isRole(CcNodeRole.PRIMARY)
        );
    }

    public isReplica() {
        return (
            this.isRole(CcNodeRole.SLAVE) ||
            this.isRole(CcNodeRole.SECONDARY) ||
            this.isRole(CcNodeRole.RESOLVING) ||
            this.isRole(CcNodeRole.REPLICA)
        );
    }

    public isReplicaOf(node: CcNode) {
        if (this.isType(CcNodeType.MYSQL) && this.replicationSlave) {
            return (
                [
                    node.hostname,
                    node.hostnameData,
                    node.hostnameInternal,
                ].includes(this.replicationSlave.masterHost) &&
                this.replicationSlave.masterPort === node.port
            );
        } else {
            //@todo implement for galera, postgres, etc... when needed
            return false;
        }
    }

    /*
    This function is the getStatusRpc from ccv1
     */
    public getStatusGroup() {
        let status;

        switch (this.hoststatus) {
            case CcNodeStatus.CmonHostOnline:
                status = CcNodeStatusGroup.success;
                break;
            case CcNodeStatus.CmonHostUnknown:
            case CcNodeStatus.CmonHostOffLine:
            case CcNodeStatus.CmonHostShutDown:
                status = CcNodeStatusGroup.warning;
                break;
            case CcNodeStatus.CmonHostRecovery:
            case CcNodeStatus.CmonHostFailed:
                status = CcNodeStatusGroup.error;
                break;
            default:
                status = CcNodeStatusGroup.info;
        }
        return status;
    }

    /**
     * Moved logic from ccv1, method getStatusExplainRpc
     */
    public getMessage() {
        return this.hoststatus !== CcNodeStatus.CmonHostOnline
            ? `${this.message}${
                  this.statereason && this.statereason !== this.message
                      ? ` (${this.statereason})`
                      : ''
              }`
            : 'Node is OK';
    }

    public isJobRelated(job: CcJob) {
        return isJobRelatedToNode(job, this);
    }
}

function getBase(nodeType?: CcNodeType | string) {
    switch (nodeType) {
        case CcNodeType.POSTGRESQL:
        case CcNodeType.TIMESCALEDB:
            return CcNodeBase.BASE_POSTGRESQL;
        default:
            return CcNodeBase.BASE_UNKNOWN;
    }
}

export function getDatabaseNodeTypes() {
    return [
        CcNodeType.MYSQL,
        CcNodeType.GALERA,
        CcNodeType.GROUP_REPLICATION,
        CcNodeType.POSTGRESQL,
        CcNodeType.TIMESCALEDB,
        CcNodeType.MONGO,
        CcNodeType.NDB,
        CcNodeType.NDB_MGMD,
        CcNodeType.REDIS,
        CcNodeType.REDIS_SHARDED,
        CcNodeType.MSSQL,
        CcNodeType.ELASTIC,
    ];
}
export function getLoadBalancerNodeTypes() {
    return [
        CcNodeType.HAPROXY,
        CcNodeType.PROXYSQL,
        CcNodeType.GARBD,
        CcNodeType.MAXSCALE,
    ];
}
export function getPerformanceHaNodeTypes() {
    return [
        ...getLoadBalancerNodeTypes(),
        CcNodeType.KEEPALIVED,
        CcNodeType.PGBOUNCER,
    ];
}

export function getOtherNodeTypes() {
    return [
        CcNodeType.CONTROLLER,
        CcNodeType.MEMCACHED,
        CcNodeType.PROMETHEUS,
        CcNodeType.PBM_AGENT,
        CcNodeType.REDIS_SENTINEL,
    ];
}

export function isNodeLoadBalancer(node: CcNode) {
    return getLoadBalancerNodeTypes().includes(node.nodetype as CcNodeType);
}
export function getNodeDistributionString(node: CcNode) {
    if (node.distribution) {
        return `${node.distribution.name} ${node.distribution.release} [${node.distribution.codename}]`;
    }
}

export function isJobRelatedToNode(job: CcJob, node: CcNode) {
    const jobHostname =
        job.spec.job_data?.node?.hostname || job.spec.job_data?.hostname;
    const jobPort = job.spec.job_data?.node?.port || job.spec.job_data?.port;
    return jobHostname === node.hostname && jobPort === node.port;
}

export function createNodeAddress(
    nodeType?: CcNodeType,
    hostname?: string,
    port?: number
) {
    return `${nodeType}://${hostname}:${port}`;
}
